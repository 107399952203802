import React, { useEffect } from "react";
import "../assets/css/style.css";
import { Link, NavLink } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { ToastContainer } from "react-toastify";
import logo from "../assets/images/brand.png";
import { toShortAddress } from "../utils/formatter";
import useWeb3 from "../hooks/useWeb3";
import useAuth from "../hooks/useAuth";

function Layout({ children, pageTitle }) {
  const { connect, address, disconnect } = useWeb3();
  const { auth } = useAuth();

  useEffect(() => {
    document.title = pageTitle || "Rolly";
    if (address) {
      connect();
    }
  }, [address]);

  async function handleWalletConnection() {
    await connect();
  }

  async function handleDisconnect() {
    await disconnect();
  }

  const navLink = document.querySelectorAll(".nav-link");
  for (let i = 0; i < navLink.length; i++) {
    navLink[i].addEventListener("click", closeMenu);
  }

  function mobileView() {
    const hamburger = document.getElementById("harm");
    const navMenu = document.querySelector(".nav-menu");
    hamburger.classList.toggle("active");
    navMenu.classList.toggle("active");
  }

  function closeMenu() {
    const hamburger = document.getElementById("harm");
    const navMenu = document.querySelector(".nav-menu");
    hamburger.classList.remove("active");
    navMenu.classList.remove("active");
  }
  return (
    <div>
      <header className="header">
        <nav className="navbar">
          <span className="nav-brand">
            <Link to="/">
              <img src={logo} alt="." className="img-fluid" />
            </Link>
          </span>

          <ul class="nav-menu">
            <li class="nav-item ">
              <NavLink to="/dashboard" class="nav-link">
                Dashboard
              </NavLink>
            </li>

            <li class="nav-item ">
              <NavLink to="/referrals" class="nav-link">
                Referrals
              </NavLink>
            </li>
            <li class="nav-item ">
              <NavLink to="/points" class="nav-link">
                Points
              </NavLink>
            </li>
            <li class="nav-item ">
              <NavLink to="/ads_revenue" class="nav-link">
                Ads Revenue
              </NavLink>
            </li>

            {auth.data && auth.data.roles.includes(9911) ? (
              <>
                <li class="nav-item ">
                  <NavLink to="/admin/pending-payment" class="nav-link">
                    Pay
                  </NavLink>
                </li>

                <li class="nav-item ">
                  <NavLink to="/admin/upload-ads" class="nav-link">
                    Upload
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>

          <div className="nav__btn">
            {address ? (
              <span className="nav_blank__btn" onClick={handleDisconnect}>
                {" "}
                {toShortAddress(address)}{" "}
              </span>
            ) : (
              <span className="nav_blank__btn" onClick={handleWalletConnection}>
                connect wallet
              </span>
            )}
          </div>

          <div class="harmburger" id="harm" onClick={mobileView}>
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
          </div>
        </nav>
      </header>

      <Container>
        {children}
        <ToastContainer />
      </Container>
    </div>
  );
}

export default Layout;
