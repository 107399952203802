import React, { useState } from "react";
import Layout from "../components/Layout";
import { Button, Container } from "semantic-ui-react";
import "./join.css";
import useWeb3 from "../hooks/useWeb3";
import axios from "../api/axios";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

function Login() {
  const {  setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";
  const { address, connect } = useWeb3();
  const [onLoad, setLoading] = useState(false);

  async function handleWalletConnection() {
    await connect();
  }

  async function login() {
    setLoading(true);
    try {
      const response = await axios.get(`/user/${address}`);
      const data = response.data;
      setAuth({ data });
      setLoading(false);
      navigate(from, { replace: true });
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        toast.error("No Server Response");
      } else if (error.response?.status === 400) {
        toast.error("Missing user wallet");
      } else if (error.response?.status === 401) {
        toast.error("Unauthorized");
      } else if (error.response?.status === 404) {
        toast.error("User doesnt exist");
        setTimeout(() => {
          navigate("/register")
        }, 2000)
      } else {
        toast.error("Failed");
      }
    }
  }

  return (
    <>
      <Layout pageTitle='login' />

      <Container>
        <div className="py-5 mt-5">
          <div className="login_details">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6 icon"
            >
              <path d="M2.273 5.625A4.483 4.483 0 0 1 5.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 3H5.25a3 3 0 0 0-2.977 2.625ZM2.273 8.625A4.483 4.483 0 0 1 5.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 6H5.25a3 3 0 0 0-2.977 2.625ZM5.25 9a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3H15a.75.75 0 0 0-.75.75 2.25 2.25 0 0 1-4.5 0A.75.75 0 0 0 9 9H5.25Z" />
            </svg>

            <div className="mt-5">
              {address ? (
                <>
                  <Button
                    loading={onLoad}
                    style={{
                      color: "#fff",
                      background:
                        "linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%)",
                    }}
                    onClick={login}
                  >
                    Sign In Message
                    <i
                      class="fa-solid fa-arrow-right"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </Button>
                </>
              ) : (
                <>
                  <h2 className="mt-5" style={{ color: "#fff" }}>
                    Wallet not connected
                  </h2>
                  <p style={{ color: "#fff" }}>
                    Please connect your wallet to continue
                  </p>{" "}
                  <Button
                    loading={onLoad}
                    style={{
                      color: "#fff",
                      background:
                        "linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%)",
                    }}
                    onClick={handleWalletConnection}
                  >
                    CONNECT WALLET
                    <i
                      class="fa-solid fa-arrow-right"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Login;
