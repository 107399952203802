import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import {  Container } from "semantic-ui-react";
import axios from "../../api/axios";
import { toast } from "react-toastify";



function SucessPay() {
  const [pending, setPending] = useState([]);

    useEffect(() => {
        const fetch = async () => {
          const url = "/admin/pending";
    
          try {
            const response = await axios.get(url);
            setPending(response.data);
            console.log(response.data);
          } catch (err) {
            console.log(err);
            toast.error("An error occurred");
          }
        };
    
        fetch();
      }, []);
    
  return (
    <>
    <Layout/>
    <Container>
        <h1>Success Payment</h1>
    </Container>
    </>
  )
}

export default SucessPay
