import { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import { ethers } from "ethers";
import { toInt } from "../utils/formatter";
import { supportedChain, addresses, abis } from "../constants";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ACTIONS = {
  SET_SIGNER: 0,
  SET_ADDRESS: 1,
  SET_BALANCE: 2,
  SET_CONNECTED: 3,
  SET_REQUESTING: 4,
  SET_USDTCONTRACT: 5,
  SET_DATA: 6,
};

const initialState = {
  signer: null,
  address: null,
  balance: {},
  connected: false,
  requesting: false,
  requestType: "",
  data_log: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_SIGNER:
      return {
        ...state,
        signer: action.payload,
      };
    case ACTIONS.SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case ACTIONS.SET_BALANCE:
      return {
        ...state,
        balance: action.payload,
      };

      case ACTIONS.SET_DATA:
        return {
          ...state,
          data_log: action.payload,
        };
    case ACTIONS.SET_CONNECTED:
      return {
        ...state,
        connected: action.payload,
      };
    case ACTIONS.SET_REQUESTING:
      return {
        ...state,
        requesting: action.payload,
      };
    case ACTIONS.SET_USDTCONTRACT:
      return {
        ...state,
        usdtContract: action.payload,
      };

    default:
      return state;
  }
};

const Web3Context = createContext(initialState);

Web3Provider.propTypes = {
  children: PropTypes.node,
};

function Web3Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const {  setAuth } = useAuth();



  const initialize = async () => {
    if (!window.ethereum) {
      toast.warning("Non-Ethereum browser detected. Open with MetaMask or Trust Wallet!")
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const networkId = (await provider.getNetwork()).chainId;
    if (networkId !== supportedChain.id) {
      toast.info(
        `Please switch to the ${supportedChain.name} network and connect again`
      );

      await switchToSupportedNetwork();
      window.location.reload();
      return;
    }

    window.provider = provider;
    await provider.send("eth_requestAccounts", []);

    const signer = provider.getSigner();
    dispatch({ type: ACTIONS.SET_SIGNER, payload: signer });

    const address = await signer.getAddress();
    dispatch({ type: ACTIONS.SET_ADDRESS, payload: address });

    const usdtContract = new ethers.Contract(
      addresses.usdt,
      abis.usdt,
      provider
    );
    if (!usdtContract) {
      window.alert("Usdt contract not detected on the selected network");
    }
    dispatch({ type: ACTIONS.SET_USDTCONTRACT, payload: usdtContract });
    let ethBalance = await provider.getBalance(address);
    ethBalance = ethers.utils.formatUnits(ethBalance, 18);
    const balance = toInt(ethBalance.toString()).toFixed(2);
    dispatch({ type: ACTIONS.SET_BALANCE, payload: balance });
    dispatch({ type: ACTIONS.SET_CONNECTED, payload: true });


    window.ethereum.on("accountsChanged", async function (accounts) {
      await initialize();
      window.location.reload();
    });
    window.ethereum.on("chainChanged", async function (networkId) {
      await initialize();
      if (networkId !== supportedChain.id) {
        toast.info(
          `Please switch to the ${supportedChain.name} network and connect again`
        );

        await switchToSupportedNetwork();
        window.location.reload();
      }
    });
  };

  const addSupportedNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `${supportedChain.idString}`,
            chainName: `${supportedChain.name}`,
            rpcUrls: [supportedChain.rpcUrl],
            blockExplorerUrls: [supportedChain.explorerUrl],
            nativeCurrency: supportedChain.nativeCurrency,
          },
        ],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const switchToSupportedNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `${supportedChain.idString}` }],
      });
    } catch (error) {
      if (error.code === 4902) {
        await addSupportedNetwork();
      }
    }
  };

  async function updateUserDb(value, tnx_hash, referralId) {
    try {
      const url = "/register";
      const newUser = {
        wallet: state.address,
        deposit: value,
        tnx_hash: tnx_hash,
        referal: referralId,
      };
      const result = await axios.post(url, newUser);
      toast.success(result.data.message);
    } catch (err) {
      toast.error(err.response.data.error);
    }
  }

  const register = async (amount, referralId) => {
    if (state.address) {
      dispatch({ type: ACTIONS.SET_REQUESTING, payload: true });
      dispatch({ type: ACTIONS.SET_REQUEST_TYPE, payload: "register" });
      try {
        if (amount >= 50 && amount <= 1000) {
          const amountInWei = ethers.utils.parseUnits(
            amount.toString(),
            "ether"
          );
          const tx = await state.usdtContract
            .connect(state.signer)
            .transfer(addresses.Bscchain, amountInWei);
          const receipt = await tx.wait();
          toast.info("Submitting transaction on blockchain")

          if (receipt && receipt.status === 1) {
            const tnx_hash = receipt.transactionHash;
            toast.info("Confirming transaction on blockchain, Please do not close this page");
            await updateUserDb(amount, tnx_hash, referralId );

            setTimeout(() => {
              navigate("/dashboard");
            }, 10000);
          } else {
            toast.error(
              "Transaction failed: Your transaction was not successful."
            );
          }
        } else {
          toast.error("Minimum must be greater than $50 and less than $1000");
        }
      } catch (error) {
        if (error.message.includes("BEP20: transfer amount exceeds balance")) {
          toast.error(
            "Insufficient funds to complete the transaction. Please make sure you have enough BSC in your wallet to cover gas fees."
          );
        }
        if (error.code === -32603 && error.data) {
          toast.error("Transfer amount exceeds balance");
        } else {
          toast.error("Transaction failed:", error.message);
        }

        if (error.message.indexOf("reject") > 0) {
          toast.error(`Transaction rejected by User.`);
        }
      }
      dispatch({ type: ACTIONS.SET_REQUESTING, payload: false });
      dispatch({ type: ACTIONS.SET_REQUEST_TYPE, payload: "" });
    } else {
      window.scrollTo(0, 0);
    }
  };


  async function updateRestakeUser(value, tnx_hash) {
    try {
      const url = '/user/re-stake'
      const data = {
        wallet: state.address,
        amount: value,
        tnx_hash: tnx_hash,
      };
      const result = await axios.post(url, data);
      toast.success(result.data.message);
    } catch (err) {
      toast.error(err.response.data.error);
    }
  }


  const ReStakeFund = async (amount) => {
    if (state.address) {
   
      try {
        if (amount >= 50 && amount <= 1000) {
          const amountInWei = ethers.utils.parseUnits(
            amount.toString(),
            "ether"
          );
          const tx = await state.usdtContract
            .connect(state.signer)
            .transfer(addresses.Bscchain, amountInWei);
          const receipt = await tx.wait();
          toast.info("Submitting transaction on blockchain")


          if (receipt && receipt.status === 1) {
            const tnx_hash = receipt.transactionHash;
            toast.info("Confirmming transaction on blockchain, Please do not close this page");
            await updateRestakeUser(amount, tnx_hash );
          } else {
            toast.error(
              "Transaction failed: Your transaction was not successful."
            );
          }
        } else {
          toast.error("Minimum must be greater than $50 and less than $1000");
        }
      } catch (error) {
        if (error.message.includes("BEP20: transfer amount exceeds balance")) {
          toast.error(
            "Insufficient funds to complete the transaction. Please make sure you have enough BSC in your wallet to cover gas fees."
          );
        }
        if (error.code === -32603 && error.data) {
          toast.error("Transfer amount exceeds balance");
        } else {
          toast.error("Transaction failed:", error.message);
        }

        if (error.message.indexOf("reject") > 0) {
          toast.error(`Transaction rejected by User.`);
        }
      }
      dispatch({ type: ACTIONS.SET_REQUESTING, payload: false });
      dispatch({ type: ACTIONS.SET_REQUEST_TYPE, payload: "" });
    } else {
      window.scrollTo(0, 0);
    }
  };

  const connect = async () => {
    await initialize();
  };

  

  const disconnect = async () => {
  
    window.location.reload();
    setAuth({});
  };

  return (
    <Web3Context.Provider
      value={{
        ...state,
        connect,
        disconnect,
        register,
        ReStakeFund
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}

export { Web3Provider, Web3Context };
