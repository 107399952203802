export const toShortAddress = (addr) => {
  if (!addr) return null;
  const address =
    addr.substring(0, 5) + "..." + addr.substring(37, addr.length);
  return address;
};

export const toLongAddress = (addr) => {
  if (!addr) return null;
  const address =
    addr.substring(0, 7) + "..." + addr.substring(35, addr.length);
  return address;
};

export const toInt = (value) => {
  return parseInt(value, 10);
};
