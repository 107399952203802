import React from 'react'
import Layout from './Layout'
import { Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

function Missing() {
  return (
    <>
    <Layout pageTitle='error'/>
    <Container>
    <section className='py-5 my-5 text-center' style={{color: "#fff"}}>

      <h1>404 PAGE NOT FOUND</h1>

      <Link to='/' >
      <h4 className='mt-5'>Click here to return home</h4>
      </Link>

    </section>
    </Container>
    </>
  
  )
}

export default Missing
