import React from "react";
import { Container } from "semantic-ui-react";
import Layout from "./Layout";

function About() {
  return (
    <>
      <Layout pageTitle='about' />
      <Container>
        <section className="py-5 text-center" style={{ color: "#fff" }}>
          <h1>About Rolly</h1>

          <p>
          <b>Welcome to Rolly</b>, an
            avant-garde project reshaping the landscape of decentralized finance
            (DeFi) on the Binance Smart Chain (BSC). Rolly is more than just an
            investment platform; it's a community-driven endeavor dedicated to
            propelling you towards financial liberation.
          </p>

          <p>
        <h3>Introduction to
            Binance Smart Chain (BSC):</h3> Binance Smart Chain (BSC), a blockchain
            network crafted by Binance, one of the world's largest
            cryptocurrency exchanges, operates in tandem with Binance Chain. It
            provides a swift and cost-effective alternative for executing smart
            contracts, akin to platforms like Ethereum. Integrated with the
            broader Binance ecosystem, BSC ensures seamless access to a myriad
            of decentralized finance (DeFi) services. 

          </p>

        

           <p>
           <h3>
          Rolly's Unique Proposition:

          </h3>
            Picture a convergence of digital assets and
            blockchain technology, forging an investment experience unparalleled
            – that's Rolly for you. Our platform seamlessly integrates with the
            Binance Smart Chain, acting as the pulsating core that ensures your
            transactions are not only efficient but also cost-effective. To
            board the Rolly train, a nominal investment in USDT is all it takes,
            exemplifying our commitment to leveraging cutting-edge blockchain
            infrastructure for your benefit.
           </p>

         
           <p>
           <h3>
           Inclusive Investment
            Opportunities:
           </h3>
           Ready to roll? Rolly welcomes investors of all
            sizes, offering a flexible entry with a minimum investment ranging
            from $50 to $1000. Inclusivity is our mantra, inviting a diverse
            community to join us on this innovative financial journey. Upon
            entering, brace yourself for a 20% Return on Investment (ROI) after
            a 30-day voyage – a gratifying incentive for those seeking to
            amplify their financial gains.

           </p>

       
           <p>
           <h3>
           Dynamic Referral System:
           </h3>
           The
            magic unfolds on the 30th day as withdrawals open up, granting
            access to your dashboard's total amount, encompassing both your ROI
            and enticing referral bonuses. Our dynamic bonus system lets you
            earn 0.5% of your referred friends' investments, regardless of the
            percentage. Withdraw your referral earnings alongside your ROI on
            the 30th day for a win-win scenario.
           </p>

          

            <p>
            <h3>   
             Human Touch with Sponsored
            Posts:</h3>
            But there's more – a human touch is added with sponsored
            posts. Share them on your preferred platforms, earn points, and each
            point translates to dollars in your pocket. 1 Rolly point equals $1,
            and the best part is withdrawing these points anytime, day or night
            – within 24 hours, your withdrawal will be deposited into your
            provided wallet address

            </p>
          <p>
            
            <h3> Community-Driven Ethos:</h3>
           Rolly transcends
            being a mere platform; it's a community-driven journey. We champion
            transparency, efficiency, and the human side of investing. Join us
            on this exhilarating ride, explore the possibilities Rolly offers,
            and let's collectively roll into a future where your financial
            dreams metamorphose into reality. Welcome to Rolly – where we're not
            just investing; we're investing in you!
          </p>
        </section>
      </Container>
    </>
  );
}

export default About;
