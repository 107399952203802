import React, { useState } from "react";
import Layout from "../components/Layout";
import { Container, Form, Button } from "semantic-ui-react";
import "./post.css";
import useWeb3 from "../hooks/useWeb3";
import axios from "../api/axios";
import { toast } from "react-toastify";

function Upload() {
  const { address } = useWeb3();
  const [header, setHeader] = useState("");
  const [desc, setDesc] = useState("");
  const [img, setImg] = useState("");
  const [load, setOnLoad] = useState(false);

  async function handlePost(e) {
    e.preventDefault();
    setOnLoad(true);
    const url = "/upload";
    try {
      const data = {
        wallet: address,
        header: header,
        desc: desc,
        img: img,
      };
      const response = await axios.post(url, data);
      toast.success(response.data.message);
      setOnLoad(false);
    } catch (err) {
      setOnLoad(false);
      console.log(err);
    }
  }
  return (
    <>
      <Layout pageTitle='upload'/>
      <Container>
        <section className="py-5">
          <div>
            <h2 className="text-center" style={{ color: "#fff" }}>
              Upload Post
            </h2>
            <div className="form__layout">
              <form onSubmit={handlePost}>
                <Form.Field>
                  <label
                    style={{ color: "#fff", margin: "10px", fontSize: "16px" }}
                  >
                    Headline
                  </label>
                  <input
                    type="text"
                    placeholder="Enter text"
                    onChange={(e) => setHeader(e.target.value)}
                    required
                  />
                </Form.Field>

                <Form.Field>
                  <label
                    style={{ color: "#fff", margin: "10px", fontSize: "16px" }}
                  >
                    Description
                  </label>
                  <br />
                  <textarea
                
                    class="custom__textarea"
                    rows="4"
                    placeholder="Description"
                    onChange={(e) => setDesc(e.target.value)}
                  ></textarea>
                </Form.Field>

                <Form.Field>
                  <label
                    style={{ color: "#fff", margin: "10px", fontSize: "16px" }}
                  >
                    Add Image Url
                  </label>
                  <input
                    type="url"
                    placeholder="image link"
                    onChange={(e) => setImg(e.target.value)}
                    required
                  />
                </Form.Field>

                <div className="mt-5">
                  <Button
                    loading={load}
                    fluid
                    style={{
                      color: "#000",
                      background:
                        "linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%)",
                    }}
                  >
                    Post
                  </Button>
                </div>
                
              </form>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default Upload;
