import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Container, Button } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";

function Points() {
  const { auth } = useAuth();
  const [points, setPoints] = useState(0);

  useEffect(() => {
    setPoints(auth.data.points);
  }, [auth]);

  return (
    <>
      <Layout pageTitle='points' />

      <Container>
        <section className="py-5 ads_page">
          <div>
            <h3> {points} points</h3>
            <span>Total points earned</span>

            <p className="mt-5" style={{ color: "#fff", textAlign: "center" }}>
              The withdrawal button will be accessible at a random time to
              withdraw your points directly to your wallet
            </p>

            <h3 className="mt-5" style={{ color: "#fff", textAlign: "center" }}>
              1 <span style={{ color: "#86AC1A" }}>ROLLY</span> is equivalent to
              1 USDT
            </h3>

            <div className="mt-5 py-5">
              <Button
                fluid
                disabled
                //   style={{
                //     color: "#000",
                //     background:
                //       "linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%)",
                //   }}
              >
                Withdraw
              </Button>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default Points;
