import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Button, Container, Form, Input } from "semantic-ui-react";
import "./join.css";
import useWeb3 from "../hooks/useWeb3";
import { useLocation } from "react-router-dom";
import { toLongAddress } from "../utils/formatter";

function Join() {
  const location = useLocation();
  const referralId = new URLSearchParams(location.search).get("ref");
  const { register, address, connect } = useWeb3();
  const [value, setValue] = useState(null);
  const [load, setLoading] = useState(false);

  useEffect(() => {});

  async function handleRegistration(event) {
    event.preventDefault();
    setLoading(true);
    await register(value, referralId);
    setLoading(false);
  }

  return (
    <>
      <Layout pageTitle='register'/>

      <Container>
        <section className="mt-5">
          <div className="first__content">
            <h2>Join Us</h2>
            <p className="text-center">Register with $50 - $1000</p>
          </div>
        </section>

        <section className="py-5 mt-5">
          <div className="form__layout">
            <p style={{ color: "#fff" }}>Ref: {toLongAddress(referralId)}</p>
            <form onSubmit={handleRegistration}>
              <Form.Field>
                <input
                  type="number"
                  placeholder="$50 - $1000"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  required
                />
              </Form.Field>

              <Form.Field>
                <span className="d-flex">
                  <Input type="checkbox" required />
                  <span
                    type="button"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                    className="mt-3 m-2"
                  >
                    Terms & Conditions
                  </span>
                </span>
              </Form.Field>

              {/* <span style={{color: "#fff"}}>
                 
                </span> */}

              <div className="mt-5">
                {address ? (
                  <Button
                    fluid
                    loading={load}
                    style={{
                      color: "#000",
                      background:
                        "linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%)",
                    }}
                  >
                    Register
                  </Button>
                ) : (
                  <Button
                    fluid
                    style={{
                      color: "#000",
                      background:
                        "linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%)",
                    }}
                    onClick={connect}
                  >
                    Connect
                  </Button>
                )}
              </div>
            </form>
          </div>
        </section>

        <section>
          <div
            class="modal fade bd-example-modal-lg"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div
                class="modal-content"
                style={{ backgroundColor: "#0f1111", color: "#fff" }}
              >
                <div className="p-5">
                  <div class="modal-header">
                    <h1 class="modal-title"> Terms and Conditions</h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <p>
                    These terms and conditions outline the rules and regulations
                    for the Rolly website.
                  </p>
                  <p>
                    By accessing this website we assume you accept these terms
                    and conditions. Do not continue to use Rolly if you do not
                    agree to take all of the terms and conditions stated on this
                    page.
                  </p>
                  <p>
                    The following terminology applies to these Terms and
                    Conditions, Privacy Statement and Disclaimer Notice and all
                    Agreements: "Client", "You" and "Your" refers to you, the
                    person log on this website and compliant to the Company’s
                    terms and conditions. "The Company", "Ourselves", "We",
                    "Our" and "Us", refers to our Company. "Party", "Parties",
                    or "Us", refers to both the Client and ourselves. All terms
                    refer to the offer, acceptance and consideration of payment
                    necessary to undertake.
                  </p>

                  <h3> Removal of links from our website</h3>
                  <p>
                    If you find any link on our Website that is offensive for
                    any reason, you are free to contact and inform us any
                    moment. We will consider requests to remove links but we are
                    not obligated to or so or to respond to you directly.
                  </p>

                  <p>
                    We do not ensure that the information on this website is
                    correct, we do not warrant its completeness or accuracy; nor
                    do we promise to ensure that the website remains available
                    or that the material on the website is kept up to date.
                  </p>
                  <h3> Disclaimers for Rolly </h3>

                  <p>
                    All the information on this website is published in good
                    faith and for general information purpose only. rolly does
                    not make any warranties about the completeness, reliability
                    and accuracy of this information. Any action you take upon
                    the information you find on this website, is strictly at
                    your own risk. rolly will not be liable for any losses
                    and/or damages in connection with the use of our website.
                  </p>
                  <p>
                    As long as the website and the information and services on
                    the website are provided free of charge, we will not be
                    liable for any loss or damage of any nature.
                  </p>

                  <h3>Consent</h3>
                  <p>
                    By using our website, you hereby consent to our disclaimer
                    and agree to its terms.
                  </p>

                  <h3>Update </h3>

                  <p>
                    Should we update, amend or make any changes to this
                    document, those changes will be prominently posted here.
                  </p>

                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default Join;
