import usdtContractAbi from "./USDT.json";

const supportedChain = {
  id: 0x38,
  idString: "0x38",
  name: "BSC Mainnet",
  rpcUrl: "https://bsc-dataseed.binance.org/",
  explorerUrl: "https://bscscan.com/",
  nativeCurrency: {
    symbol: "BNB",
    decimals: 18,
  },
};


const addresses = {
  usdt: "0x55d398326f99059fF775485246999027B3197955",
  Bscchain: "0x6AC5c15f250dB8Fd32fA656925cb48BAB84ADc32"
};

const abis = {
  usdt: usdtContractAbi,
};

export { supportedChain, addresses, abis };
