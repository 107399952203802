import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import Layout from "../components/Layout";
import "./join.css";
import useWeb3 from "../hooks/useWeb3";
import ClipboardJS from "clipboard";
import { toast } from "react-toastify";
import axios from "../api/axios";
import "./Home.css";

const WEB_LINK = "https://stakerolly.com/register?ref=";

function Referrals() {
  const { address } = useWeb3();
  const [allRefs, setRefs] = useState([]);

  useEffect(() => {
    const getRefs = async () => {
      const url = `/user-ref/${address}`;

      try {
        const response = await axios.get(url);
        setRefs(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    getRefs();

    const clipboard = new ClipboardJS("#copyButton", {
      text: () => `${WEB_LINK}${address}`,
    });

    clipboard.on("success", (e) => {
      toast.success("Copied");
      clipboard.destroy();
    });
    return () => {
      clipboard.destroy();
    };
  }, [address]);

  function sharePost() {
    alert("sharing");
  }
  return (
    <>
      <Layout pageTitle='referrals'/>
      <Container>
        <section className="text-center py-5 frens">
          <h1>
            Invite your <span style={{ color: "#86AC1A" }}>friends</span> & earn
            3% of their deposit
          </h1>
        </section>

        <section className="py-5">
          <div className="d-flex socials">
            <div className="filled__btn" id="copyButton">
              <i class="fa-solid fa-link"></i>
              Copy link
            </div>

            <div className="blank__btn" onClick={sharePost}>
              <i class="fa-solid fa-share"></i>
              Share
            </div>
          </div>
        </section>

        <section className="py-5">
          {allRefs && allRefs.length > 0 ? (
            <>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <table class="ui celled">
                  <thead>
                    <tr>
                      <th>Referral Wallet</th>
                      <th>Referral Earnings</th>
                    </tr>
                  </thead>

                  <tbody>
                    {allRefs.map((e, i) => (
                      <tr key={i}>
                        <td>{e.wallet}</td>
                        <td>${e.ref_earning}</td>
                      </tr>
                    ))}
                    {/* <tr>
                  <td>
                    <b>Total Tokens</b>
                  </td>
                  <td>
                    {" "}
                    <span style={{ color: "grey" }}> 63,000,000,000,000</span>
                  </td>
                </tr> */}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <h2 className="text-center">No Referrals Yet</h2>
          )}
        </section>
      </Container>
    </>
  );
}

export default Referrals;
