import React from 'react'
import { Container } from 'semantic-ui-react'

function Unauthorized() {
  return (
   <>
   <Container>
    <section className='py-5 mt-5'>
        <div style={{textAlign: "center"}}>
            <h1 style={{color: "#fff", fontSize: "30px"}}>401 UNAUTHORIZED</h1>
        </div>

        <div style={{textAlign: "center"}} className='mt-5'>
          <img className='img-fluid' style={{width: "300px"}} src='https://media.istockphoto.com/id/1463129262/vector/account-is-blacklisted-badge-with-user-account-icon-ban-sign-and-text.jpg?s=612x612&w=0&k=20&c=rRImgA8QxEv6CRqSS4Ifx9KvaAXn_djAMmwQ799Wa1s=' alt='.'/>
        </div>
    </section>
   </Container>
   </>
  )
}

export default Unauthorized
