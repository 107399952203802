// GlobalStyles.js

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background: #0e0e0e;
    color: #86AC1A;
  }
`;

export default GlobalStyle;
