import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Button, Container, Form, Loader } from "semantic-ui-react";
import "./Withdraw.css";
import useAuth from "../hooks/useAuth";
import useWeb3 from "../hooks/useWeb3";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";

export default function Withdraw() {
  const navigate = useNavigate();
  const [ready, setRediness] = useState(true);
  const { auth } = useAuth();
  const { address } = useWeb3();
  const [balance, setbalance] = useState(10);
  const [load, setLoading] = useState(false);
  const [tnx, setTnx] = useState([]);

  useEffect(() => {
    checkDues();
  }, [auth]);

  async function checkDues() {
    if (isWithin30Days(auth.data.sub_date)) {
      setRediness(false);
      const roi = auth.data.roi;
      const bal = auth.data.balance
      const total = roi + bal;
      setbalance(total);
      await fetchTransactions();
    } else {
      navigate("/unauthorized");
    }
  }

  async function fetchTransactions() {
    try {
      const url = `/user/pay-request/${address}`;
      const response = await axios.get(url);
      setTnx(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  function isWithin30Days(sub_date) {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const serverDate = new Date(sub_date);
    return serverDate < thirtyDaysAgo;
  }

  // function isWithin24Hours(sub_date) {
  //   const twentyFourHoursAgo = new Date();
  //   twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 240);

  //   const serverDate = new Date(sub_date);
  //   return serverDate < twentyFourHoursAgo;
  // }

  async function handleWithdrawal(e) {
    e.preventDefault();
    setLoading(true);
    const url = "/user/request";
    if (balance > 0) {
      try {
        const data = {
          wallet: address,
          amount: balance,
        };
        const response = await axios.post(url, data);
        toast.success("Payment request was successful.");
        setLoading(false);
        setbalance(0);
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (!error?.response) {
          toast.error("No Server Response");
        } else if (error.response?.status === 400) {
          toast.error("Insufficient balance");
        } else if (error.response?.status === 401) {
          toast.error("Unauthorized");
        } else if (error.response?.status === 404) {
          toast.error("User doesnt exist");
        } else {
          toast.error("Failed");
          setLoading(false);
        }
        setLoading(false);
      }
    } else {
      toast.error("Balance too low");
      setLoading(false);
    }
  }

  if (ready) {
    return (
      <>
        <Loader size="large" active />;
      </>
    );
  }
  return (
    <>
      <Layout pageTitle='withdraw'/>

      <Container>
        <section className="mt-5">
          <div className="first__content">
            <h3>Withdrawable balance</h3>
            <p>
              <i
                class="fa-solid fa-sack-dollar"
                style={{ color: "#86AC1A", marginRight: "5px" }}
              ></i>
              {balance}
            </p>
          </div>
        </section>

        <section className="py-5">
          <div className="form__layout">
            <form onSubmit={handleWithdrawal}>
              <Form.Field>
                <input
                  type="number"
                  placeholder="amount"
                  value={balance}
                  required
                  disabled
                />
              </Form.Field>

              <div className="mt-4">
                <Button
                  loading={load}
                  fluid
                  style={{
                    color: "#000",
                    background:
                      "linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%)",
                  }}
                >
                  Withdraw
                </Button>

                <p
                  style={{
                    color: "grey",
                    marginTop: "20px",
                    fontWeight: "light",
                    fontSize: "13px",
                  }}
                >
                  Your withdrawal will be paid to your wallet address in 24
                  hours.
                </p>
              </div>
            </form>
          </div>
        </section>

        <section className="py-5 mt-5">
          <div className="table__width">
            <h3 style={{ color: "#fff" }}>Transactions</h3>
            <table class="ui celled ">
              <thead class="">
                <tr class="">
                  <th class="">Wallet</th>
                  <th class="">Amount</th>
                  <th class="">Date</th>
                  <th class="">Transaction</th>
                  <th class="">Status</th>
                </tr>
              </thead>
              <tbody class="">
                {tnx && tnx.length > 0 ? (
                  <>
                    {tnx.map((e, i) => (
                      <tr class="" key={i}>
                        <td class=""> {e.wallet} </td>
                        <td class=""> {e.amount} </td>
                        <td class=""> {e.date} </td>
                        <td class=""> <a href={`https://bscscan.com/tx/${e.tnx_hash}`} target="_blank" rel="noreferrer" >{e.tnx_hash}</a>  </td>
                        <td class="">
                          {" "}
                          {e.pay_status ? (
                            <div>
                              {" "}
                              <span style={{ color: "green" }}>
                                Success
                              </span>{" "}
                            </div>
                          ) : (
                            <div>
                              {" "}
                              <span style={{ color: "orange" }}>
                                Pending
                              </span>{" "}
                            </div>
                          )}{" "}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr class="">
                    <td class="">No Transactions</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </Container>
    </>
  );
}
