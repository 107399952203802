import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Button, Container } from "semantic-ui-react";
import "./post.css";
import axios from "../api/axios";
import useWeb3 from "../hooks/useWeb3";
import { toast } from "react-toastify";

function SponsoredPost() {
  const { address } = useWeb3();
  const [header, setHeader] = useState("");
  const [desc, setDesc] = useState("");
  const [img, setImg] = useState("");
  const [viewed, setVeiwers] = useState([]);

  const [twitterShared, setTwitterShared] = useState(false);
  const [twitterLoad, setTwitterLoad] = useState(false);
  const [whatsappShared, setWhatsappShared] = useState(false);
  const [whatsappLoad, setWhatsappLoad] = useState(false);
  const [facebookLoad, setFacebookLoad] = useState(false);
  const [facebookShared, setFacebookShared] = useState(false);
  const [loadClaim, setloadClaim] = useState(false);

  const shareToTwitter = () => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      desc
    )}&url=${encodeURIComponent(window.location.href)}`;
    window.open(url, "_blank");
    setTwitterLoad(true);
    setTimeout(() => {
      setTwitterShared(true);
      setTwitterLoad(false);
    }, 12000);
  };

  const shareToWhatsApp = () => {
    const url = `https://wa.me/?text=${encodeURIComponent(
      desc
    )} ${encodeURIComponent(window.location.href)}`;
    window.open(url, "_blank");
    setWhatsappLoad(true);
    setTimeout(() => {
      setWhatsappShared(true);
      setWhatsappLoad(false);
    }, 12000);
  };

  const shareToFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      window.location.href
    )}&quote=${encodeURIComponent(desc)}`;
    window.open(url, "_blank");
    setFacebookLoad(true);
    setTimeout(() => {
      setFacebookShared(true);
      setFacebookLoad(false);
    }, 12000);
  };

  useEffect(() => {
    const getToday = async () => {
      const url = "/post";
      try {
        const response = await axios.get(url);
        setHeader(response.data.header);
        setDesc(response.data.desc);
        setImg(response.data.img);
        setVeiwers(response.data.viewedUsers);
      } catch (err) {
        console.log(err);
      }
    };

    getToday();
  }, []);

  const getButtonContent = (isShared) => {
    return isShared ? "Done " : "Share";
  };

  async function performTask() {
    const url = "/user/sharePost";
    setloadClaim(true);
    try {
      const data = {
        wallet: address,
      };
      const response = await axios.post(url, data);
      console.log(response);
      toast.success("Points claimed successfully");
      setloadClaim(false);
    } catch (err) {
      console.log(err);
      toast.error("An error occured");
      setloadClaim(false);
    }
  }

  return (
    <>
      <Layout pageTitle='post' />
      <Container>
        <section>
          <div className="py-5 post__image">
            <img alt="." src={img} className="img-fluid" />
          </div>
        </section>

        <section className="ads_page">
          <div className="py-5">
            <h1 style={{ textAlign: "start", color: "#fff" }}>
              Title: {header}
            </h1>
            <h3 style={{ textAlign: "start" }}>Description</h3>
            <p style={{ color: "#fff", textAlign: "start" }}>{desc}</p>
          </div>
          <div className="py-5">
            <p>Share this post to earn points</p>

            {viewed.includes(address) ? (
              <Button fluid disabled>
                Completed
              </Button>
            ) : (
              <Button
                type="button"
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
                fluid
                style={{
                  color: "#000",
                  background:
                    "linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%)",
                }}
              >
                Share
              </Button>
            )}
          </div>
        </section>

        <section>
          <div
            class="modal fade bd-example-modal-lg"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div
                class="modal-content"
                style={{ backgroundColor: "#0f1111", color: "#fff" }}
              >
                <div className="p-5">
                  <h2>Ads Revenue</h2>
                  <span>
                    By sharing ads daily you earn 0.3 rolly per-day which is
                    total of 0.9 rolly daily.
                  </span>

                  <div className="row p-2 mt-2">
                    <div className="col-12 col-lg-4 col-md-6 m-2">
                      <div className="d-flex">
                        <Button
                          onClick={shareToTwitter}
                          disabled={twitterShared}
                          loading={twitterLoad}
                        >
                          {getButtonContent(twitterShared)} on Twitter
                        </Button>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-6 m-2">
                      <div className="d-flex">
                        <Button
                          onClick={shareToFacebook}
                          disabled={facebookShared}
                          loading={facebookLoad}
                        >
                          {getButtonContent(facebookShared)} on Facebook
                        </Button>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-6 m-2">
                      <div className="d-flex">
                        <Button
                          onClick={shareToWhatsApp}
                          disabled={whatsappShared}
                          loading={whatsappLoad}
                        >
                          {getButtonContent(whatsappShared)} on Whatsapp
                        </Button>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-6 mt-3">
                      <div className="d-flex">
                        {twitterShared && whatsappShared && facebookShared ? (
                          <Button onClick={performTask} loading={loadClaim}>
                            Claim
                          </Button>
                        ) : (
                          <Button disabled>Claim</Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default SponsoredPost;
