import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Button, Container } from "semantic-ui-react";
import axios from "../../api/axios";
import useWeb3 from "../../hooks/useWeb3";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { addresses, abis } from "../../constants";

function PendingPay() {
  const { address, signer, provider } = useWeb3();
  const [pending, setPending] = useState([]);
  const [loadingMap, setLoadingMap] = useState({});
  const [paidItems, setPaidItems] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const url = `/admin/pending/${address}`;

      try {
        const response = await axios.get(url);
        setPending(response.data);
        console.log(response.data);
      } catch (err) {
        console.log(err);
        toast.error("An error occurred");
      }
    };

    fetch();
  }, [address]);

  async function handlePayment(payId, wallet, amount) {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [payId]: true,
    }));

    try {
      const amountInWei = ethers.utils.parseUnits(amount.toString(), "ether");
      const usdtContract = new ethers.Contract(
        addresses.usdt,
        abis.usdt,
        provider
      );
      const tx = await usdtContract
        .connect(signer)
        .transfer(wallet, amountInWei);
      const receipt = await tx.wait();
      toast.info("Confirming transaction on the blockchain...");


      if (receipt && receipt.status === 1) {
        const tnx_hash = receipt.transactionHash;
        toast.success("Transaction was successful");
        const url = "/admin/pay";
        const data = {
          wallet: wallet,
          payer: address,
          pay_id: payId,
          amount: amount,
          tnx_hash: tnx_hash,
        };
        const response = await axios.post(url, data);
        console.log(response);
        toast.success("Payment was successfull");
        setLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          [payId]: false,
        }));
        setPaidItems([...paidItems, payId]);
      } else {
        toast.error("Transaction failed: Your transaction was not successful.");
      }
    } catch (error) {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [payId]: false,
      }));
      if (error.message.includes("BEP20: transfer amount exceeds balance")) {
        toast.error(
          "Insufficient funds to complete the transaction. Please make sure you have enough BSC in your wallet to cover gas fees."
        );
      }
      if (error.code === -32603 && error.data) {
        toast.error("Transfer amount exceeds balance");
      } else {
        toast.error("Transaction failed:", error.message);
      }

      if (error.message.indexOf("reject") > 0) {
        toast.error(`Transaction rejected by User.`);
      }
    }
  }

  return (
    <>
      <Layout />
      <Container>
        <section className="py-5">
          <div>
            <h1 style={{ color: "#fff" }}>Pending Payments</h1>
            <h3 style={{ color: "#fff" }}>Total: {pending.length} </h3>
          </div>

          <div className="mt-5">
            {pending && pending.length > 0 ? (
              <>
                <table className="ui celled table">
                  <thead className="">
                    <tr className="">
                      <th className="">Pay Id</th>
                      <th className="">Wallet</th>
                      <th className="">Amount</th>
                      <th className="">Approval</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {pending.map((e, i) => (
                      <tr className="" key={i}>
                        <td className="">{e._id} </td>
                        <td className="">{e.wallet}</td>
                        <td className="">{e.amount}</td>
                        <td className="">
                          {paidItems.includes(e._id) ? (
                            <Button disabled>Paid</Button>
                          ) : (
                            <Button
                              onClick={() =>
                                handlePayment(e._id, e.wallet, e.amount)
                              }
                              loading={loadingMap[e._id]}
                            >
                              Pay
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <h3>NO DATA</h3>
              </>
            )}
          </div>
        </section>

        <section></section>
      </Container>
    </>
  );
}

export default PendingPay;
