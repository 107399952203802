import React from "react";
import { Container } from "semantic-ui-react";
import Layout from "../components/Layout";

function Terms() {
  return (
    <>
      <Layout pageTitle='terms&conditions' />
      <Container>
        <section className="py-5" style={{ color: "#fff" }}>
          <h1> Terms and Conditions</h1>
          <h3>Welcome to Rolly!</h3>
          <p>
            These terms and conditions outline the rules and regulations for the
            Rolly website.
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use Rolly if you do not agree to take
            all of the terms and conditions stated on this page.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company’s terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake.
          </p>

          <h3> Removal of links from our website</h3>
          <p>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </p>

          <p>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </p>
          <h3> Disclaimers for Rolly </h3>

          <p>
            All the information on this website is published in good faith and
            for general information purpose only. rolly does not make any
            warranties about the completeness, reliability and accuracy of this
            information. Any action you take upon the information you find on
            this website, is strictly at your own risk. rolly will not be liable
            for any losses and/or damages in connection with the use of our
            website.
          </p>
          <p>
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </p>

          <h3>Consent</h3>
          <p>
            By using our website, you hereby consent to our disclaimer and agree
            to its terms.
          </p>

          <h3>Update </h3>

          <p>
            Should we update, amend or make any changes to this document, those
            changes will be prominently posted here.
          </p>
        </section>
      </Container>
    </>
  );
}

export default Terms;
