import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useWeb3 from "../hooks/useWeb3";
import { useEffect } from "react";

const RequireAuth = ({ allowedRoles }) => {
  const { connect } = useWeb3();
  const { auth } = useAuth();
  const location = useLocation();

  useEffect(() => {
    connect();
  });

  return auth.data &&
    auth.data.roles.find((role) => allowedRoles.includes(role)) ? (
    <Outlet />
  ) : auth.data ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );


};

export default RequireAuth;
