import "./App.css";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import { Loader } from "semantic-ui-react";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./GlobalStyle";
import Join from "./pages/Join.js";
import Withdraw from "./pages/Withdraw.js";
import SponsoredPost from "./pages/SponsoredPost.js";
import Upload from "./pages/Upload.js";
import Referrals from "./pages/Referrals.js";
import Points from "./pages/Points.js";
import { Web3Provider } from "./contexts/web3Context";
import Login from "./pages/Login.js";
import Missing from "./components/Missing.js";
import RequireAuth from "./components/RequireAuth.js";
import { AuthProvider } from "./contexts/AuthProvider";
import About from "./components/About.js";
import PendingPay from "./pages/Admin/PendingPay.js";
import SucessPay from "./pages/Admin/SucessPay.js";
import Unauthorized from "./components/Unauthorized.js";
import Terms from "./pages/Terms.js";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';



if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const Home = lazy(() => import("./pages/Home.js"));
const Dashboard = lazy(() => import("./pages/Dashboard.js"));


function App() {
  return (
    <Router>
      <Web3Provider>
        <AuthProvider>
          <GlobalStyle />
          <Suspense
            fallback={
              <div className="text-center py-5 mt-5">
                <Loader size="large" active></Loader>
              </div>
            }
          >
            <Routes>
              {/* PUBLIC */}
              <Route path="/" element={<Home />} />
              <Route path="/register" element={<Join />} />
              <Route path="/login" element={<Login />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/unauthorized" element={<Unauthorized />} />

              {/* PRIVATE */}
              <Route element={<RequireAuth allowedRoles={[1111]} />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/referrals" element={<Referrals />} />
                <Route path="/points" element={<Points />} />
                <Route path="/withdraw" element={<Withdraw />} />
                <Route path="/ads_revenue" element={<SponsoredPost />} />
              </Route>

              {/* ADMIN */}

              <Route element={<RequireAuth allowedRoles={[9911]} />}>
              <Route path="/admin/pending-payment" element={<PendingPay/>} />
              <Route path="/admin/success-payment" element={<SucessPay/>} />
              <Route path="/admin/upload-ads" element={<Upload />} />
              </Route>
              


              {/* CATCH ALL */}

              <Route path="*" element={<Missing/>} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </Web3Provider>
    </Router>
  );
}

export default App;
